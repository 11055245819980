$primary-color: #17a2b8;
$dark-color: #343a40;
$light-color: #f4f4f4;
$danger-color: #b80315;
$success-color: #28a745;
$warning-color: #ffc107;

@mixin background {
  background-image: url('../images/background.jpg');
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
}

@mixin mediaSm {
  @media screen and (max-width: 500px) {
    @content;
  }
}

@mixin mediaMd {
  @media screen and (max-width: 799px) {
    @content;
  }
}

@mixin mediaLg {
  @media screen and (max-width: 1170px) {
    @content;
  }
}

@mixin mediaXl {
  @media screen and (min-width: 1171px) {
    @content;
  }
}
