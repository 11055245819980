@import 'config';

html {
  font-size: 18px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  @include background;
  font-family: 'Raleway', sans-serif;
  line-height: 1.6;
  color: black;
  display: flex;
  justify-content: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Utilities */
.container {
  max-width: 90vw;
  background-color: transparent;
  overflow: hidden;
  padding: 0 2rem 0 2rem;
}

/* Text Styles*/
.large {
  font-size: 3rem;
  line-height: 1.2;
  margin: 1rem 0;
}

.lead {
  font-size: 1.5rem;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: 700;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   margin: 0 0 0 0.3rem;
// }

a {
  text-decoration: none;
}

pre {
  white-space: pre-wrap;
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  margin: 0 0 0 0.5rem;
}

.btn {
  background: $dark-color;
  color: #fff;
  border-radius: 5px;
  padding: 0.4rem 1.3rem;
  cursor: pointer;
  margin: 1rem 0.5rem 1rem 0;
  opacity: 0.7;
  transition: opacity 0.2s ease-in;
  outline: none;
}

.btn-primary {
  color: $primary-color;
}

.btn-success {
  color: $success-color;
}

.btn-warning {
  color: $warning-color;
}

.btn-danger {
  color: $danger-color;
}

.btn:hover {
  opacity: 0.8;
}

.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: $light-color;
  color: #333;
}

.alert-success {
  background: $success-color;
  color: #fff;
}

.alert-danger {
  background: $danger-color;
  color: #fff;
}

/* Forms */
.form .form-group {
  margin: 1.2rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: $light-color;
}

.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form select,
.form textarea {
  background: transparent;
  display: block;
  width: 100%;
  padding: 0.4rem;
  border: none;
  border-bottom: 1px solid $dark-color;
  color: $dark-color;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $dark-color;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $light-color;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $light-color;
}

.form input[type='submit'],
button {
  font: inherit;
}

// Dropdown
.dropdown {
  position: relative;
  display: block;
  button {
    text-align: left;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  width: 100%;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: $light-color;
}

.dropdown:hover .dropdown-content {
  display: block;
}

// Not Found Page
.notFound {
  margin: 3rem 0;
  p {
    margin: 3rem 0;
  }
  .btn-container {
    display: flex;
    justify-content: center;
    .notFoundBtn {
      font-size: 1.5rem;
      padding: 1rem 2rem;
    }
  }
}

// Navbar
.navbar {
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-color: $dark-color;
  font-size: 16px;
  font-weight: 600;
}

.icon {
  display: none;
  border: 2px solid #fff;
  border-radius: 5px;
  padding: 0 0.5rem;
  color: #fff;
  background: $dark-color;
  margin: 1rem;
}

.navbar a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navbar .icon {
  display: none;
}

.nav-dropdown {
  float: left;
  overflow: hidden;
}

.nav-dropdown .nav-dropbtn {
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  margin: 0;
}

.nav-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.nav-dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.navbar a:hover,
.nav-dropdown:hover .nav-dropbtn {
  background-color: #555;
  color: white;
}

.nav-dropdown-content a:hover {
  background-color: #ddd;
  color: black;
}

.nav-dropdown:hover .nav-dropdown-content {
  display: block;
}

@media screen and (max-width: 991px) {
  .navbar {
    display: block;
  }
  .navbar a,
  .nav-dropdown .nav-dropbtn {
    display: none;
  }
  .navbar button.icon {
    float: right;
    display: block;
    font-size: 18px;
  }

  .navbar.responsive {
    position: relative;
    font-size: 18px;
  }
  .navbar.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .navbar.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .navbar.responsive .nav-dropdown {
    float: none;
  }
  .navbar.responsive .nav-dropdown-content {
    position: relative;
  }
  .navbar.responsive .nav-dropdown .nav-dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }
}

// Footer
.footer {
  position: static;
  background-color: $dark-color;
  color: #fff;
  padding: 0.7rem 0;
  vertical-align: middle;
  a {
    color: #fff;
  }
}

// Home
.home {
  display: flex;
  flex-direction: column;
  .home-content {
    padding: 0 2rem 0 3rem;
    .home-img {
      display: flex;
      justify-content: center;
      margin: 2rem 0;
      img {
        border-radius: 5px;
        width: 300px;
        height: 300px;
      }
    }
  }
  .beratung-p {
    font-size: 1.3rem;
  }
  .home-heading {
    margin-bottom: 0.5rem;
  }
  .home-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
    justify-content: center;
  }
  .home-main-container {
    display: flex;
    flex-direction: column;
  }

  h1,
  h4 {
    margin: 0 0 0.1rem 0;
  }
  a {
    color: #5f012d;
  }
  .home-link-heading {
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
  }
  .home-text {
    margin-top: 2rem;
    p {
      margin: 1rem 0 1rem 0.5rem;
    }
  }
  .home-gallery {
    display: flex;
    justify-content: center;
    margin-bottom: 7rem;
    .home-books {
      display: inherit;
      flex-direction: column;
      p {
        display: flex;
        justify-content: center;
      }
    }
  }
  span {
    font-size: 1.3rem;
    font-weight: 600;
  }
  li {
    font-size: 1.1rem;
  }
}

// Books
.books {
  display: grid;
  grid-template-columns: 45% 55%;
  margin-top: 2rem;
  padding: 0 1rem;
}
.book-cover {
  display: flex;
  flex-direction: column;
  img {
    width: 190px;
    height: 300px;
  }
  .book-container {
    display: block;
    p {
      margin: 1rem 0;
    }
  }
}

// Book
.book-details {
  display: flex;
  padding: 2rem 1rem;
  img {
    width: 280px;
    height: 400px;
  }
  .book-content {
    padding-left: 2rem;
    a {
      display: block;
      width: 100%;
      font-size: 1.5rem;
      font-weight: 700;
      background-color: $success-color;
      border: none;
    }
    p {
      margin: 0.5rem 0;
    }
    table {
      width: 60%;
    }
  }
}

.book-reading {
  padding: 1rem;
  .reading {
    display: block;
    margin: 1rem 0;
  }
}

.book-footer .footer-links {
  display: flex;
  justify-content: space-around;
  padding-bottom: 2rem;
  a {
    width: 200px;
    text-align: center;
  }
}

.guestbook {
  padding: 2rem 1rem;
}

.guestbook-details {
  display: flex;
  img {
    width: 190px;
    height: 300px;
  }
  .guestbook-content {
    padding-left: 2rem;
  }
}

.guestbook-footer {
  display: block;
  color: black;
  .guest-post {
    margin-top: 2rem;
    width: 100%;
    background-color: rgb(161, 161, 161);
    box-shadow: 0 4px 8px 0 rgba(3, 3, 3, 0.4);
    transition: 0.3s;
    border-radius: 5px;
    padding: 1rem 2rem;
  }
  .guest-post:hover {
    box-shadow: 0 8px 16px 0 rgba(3, 3, 3, 0.4);
  }
  .post-author {
    i {
      float: right;
      font-size: 1.5rem;
    }
    i:hover {
      color: $light-color;
    }
  }
  .post-content {
    width: 100%;
    background: $light-color;
    border-radius: 5px;
    padding-left: 1rem;
  }
  .post-date {
    color: #fff;
  }
}

.reading {
  display: flex;
  justify-content: center;
}

//Gallery
.gallery-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  .gallery-container {
    columns: 5 200px;
    column-gap: 1.5rem;
    width: 90%;
    margin: 1rem auto;
    overflow: hidden;
    video {
      width: 100%;
    }
    a {
      width: 150px;
      margin: 0 1.5rem 1.5rem 0;
      display: inline-block;
      overflow: hidden;
      width: 100%;
      color: #333;
      border: solid 2px black;
      padding: 5px;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      transition: all 0.25s ease-in-out;
      &:hover img {
        filter: grayscale(0);
      }
      &:hover {
        border-color: coral;
      }
      img {
        width: 100%;
        // filter: grayscale(100%);
        border-radius: 5px;
        transition: all 0.25s ease-in-out;
      }
      p {
        margin: 5px 0;
        padding: 0;
        color: black;
        text-align: center;
        font-style: italic;
      }
    }
  }
}

.gallery-details {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  .gallery-content,
  .gallery-img {
    display: inherit;
    justify-content: center;
    img {
      max-width: 500px;
      max-height: 500px;
      width: 110%;
      height: 110%;
    }
  }
  .gallery-player {
    padding: 2rem;
    min-width: 60vw;
    min-height: 50vh;
  }
  table {
    width: 50%;
    margin: 2rem 0;
  }
}

.gallery-footer {
  padding-bottom: 2rem;
  text-align: center;
  p {
    margin-bottom: 1.5rem;
  }
  a {
    text-align: center;
  }
}

.gallery-main {
  padding: 2rem 2rem 4rem 2rem;
  p {
    margin: 1rem 0 1rem 0.5rem;
  }
  ul {
    margin-left: 2rem;
  }
  .gallery-links {
    display: flex;
    justify-content: center;
    a {
      margin: 1rem;
    }
  }
}

// Graphology
.graphology {
  padding: 2rem;
  p {
    margin: 1rem 0;
  }
  .graph-header {
    display: grid;
    grid-template-columns: 30% 70%;
    margin-bottom: 1rem;
    .head-img {
      display: flex;
      justify-content: center;
      img {
        border-radius: 5px;
        width: 260px;
        height: 260px;
      }
    }
  }
  ul {
    margin-left: 2.5rem;
  }

  h3 {
    margin-left: 1rem;
  }

  .sample-img {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
    img {
      width: 50vw;
    }
  }
}

// Consulting
.consulting {
  padding: 2rem;
  .consulting-head {
    margin-bottom: 1.2rem;
  }
  .consulting-header {
    display: grid;
    grid-template-columns: 30% 70%;
    .head-img {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
      img {
        border-radius: 5px;
      }
    }
  }
  ul {
    margin-left: 2rem;
  }
  .online-heading {
    margin-bottom: 1rem;
  }
  .hypnose-parag {
    padding: 0.5rem 0 0.5rem 0.5rem;
    span {
      color: $primary-color;
    }
  }
}

// Signin
.signin {
  padding: 2rem;
  .auth-btn {
    color: $primary-color;
  }
}
// Dashboard
.dashboard {
  padding: 2rem;
  .dashboard-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .contact-btn {
    margin-bottom: 1rem;
  }
  .section .btn {
    display: flex;
    width: 100%;
  }
}

.dashboard-form {
  padding: 2rem 1rem;
}

.dashboard-book-list img {
  width: 180px;
  height: 280px;
}

// Contact
.contact {
  padding: 2rem 1rem;
  height: 100%;
  .contact-top {
    display: flex;
    justify-content: center;
    overflow: none;
    table {
      padding: 1rem;
      width: 100%;
      td {
        padding: 1rem 0;
      }
      .td-left {
        width: 30%;
        i {
          font-size: 2rem;
        }
      }
      .td-right {
        width: 70%;
      }
    }
    a {
      color: $primary-color;
    }
  }
}
.contact-map {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr;
  padding: 1rem;
  height: 500px;
  .map {
    width: 100%;
    height: 600px;
  }
}

.contact-link {
  margin-top: 1rem;
}

.impressum {
  padding: 2rem;
  p {
    margin: 1rem 0;
  }
  a {
    color: $primary-color;
  }
}

.datenschutz {
  padding: 2rem 1rem;
  ul {
    padding-left: 2rem;
  }
  hr {
    margin: 1rem 0;
  }
}

@import 'mobile';
