@include mediaXl {
}

@include mediaLg {
  html {
    font-size: 18px;
  }

  body {
    background-image: url('../images//background-lg.jpg');
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
  }

  .home {
    .home-content {
      .home-img {
        img {
          width: 16rem;
          height: 16rem;
        }
      }
    }
  }

  .dynamic-content {
    font-size: inherit;
  }

  .books {
    display: grid;
    grid-template-columns: 55% 45%;
    margin-top: 2rem;
    pre {
      font-size: 14px;
    }
  }

  .book-cover {
    display: flex;
    flex-direction: column;
    .book-container {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
      .book-img {
        width: 80%;
        img {
          width: 100%;
          height: auto;
        }
      }
      p {
        margin: 1rem 0;
      }
    }
  }

  .book-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .book-img {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
    }
    h1 {
      text-align: center;
    }
    img {
      width: 14em;
      height: 21rem;
    }
    .book-content table {
      width: 100%;
    }
  }

  .book-footer {
    display: flex;
    justify-content: center;
    .footer-links {
      display: flex;
      flex-direction: column;
    }
    a {
      margin: 1rem 0;
      width: 200px;
      text-align: center;
    }
  }

  .consulting {
    padding: 2rem 3rem;
    img {
      width: 12rem;
      height: 12rem;
    }
    .consulting-header,
    .graph-header {
      display: grid;
      grid-template-columns: 1fr;
      .head-img {
        text-align: center;
      }
    }
  }

  .graphology {
    .graph-header {
      display: flex;
      flex-direction: column;
      .head-img img {
        width: 12rem;
        height: 12rem;
      }
    }
  }
}

@include mediaMd {
  html {
    font-size: 14px;
  }

  .home {
    .home-links {
      grid-gap: 0.5rem;
    }
  }

  .books {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-gap: 1rem;
    margin-top: 2rem;
    h1 {
      color: $success-color;
    }
  }
  .book-cover {
    display: flex;
    flex-direction: column;
    img {
      width: 15rem;
      height: 22rem;
    }
    .book-container {
      display: block;
      p {
        margin: 1rem 0;
      }
    }
  }

  .book-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .book-img {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
    }
    h1 {
      text-align: center;
    }
    img {
      width: 14em;
      height: 21rem;
    }
    .book-content table {
      width: 100%;
    }
  }

  .book-footer {
    display: flex;
    justify-content: center;
    .footer-links {
      display: flex;
      flex-direction: column;
    }
    a {
      margin: 1rem 0;
      width: 200px;
      text-align: center;
    }
  }

  .guestbook-details {
    display: block;
    text-align: center;
    img {
      width: 13em;
      height: 20rem;
    }
  }

  .gallery-details {
    img {
      width: 50vw;
    }
    table {
      width: 50%;
    }
  }
  .consulting,
  .graphology {
    img {
      width: 12rem;
      height: 15rem;
    }
    .consulting-header,
    .graph-header {
      display: grid;
      grid-template-columns: 1fr;
      .head-img {
        text-align: center;
      }
    }
  }
}

@include mediaSm {
  html {
    font-size: 11px;
  }

  .container {
    max-width: 100%;
    padding: 0;
  }

  .home {
    .home-content {
      padding: 0 0.5rem;
      .home-img {
        img {
          width: 12rem;
          height: 12rem;
        }
      }
    }
    .home-links {
      grid-gap: 1.1rem;
    }
    pre {
      font-size: 1.3rem;
    }
  }

  .books {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 0 1rem;
    margin-top: 2rem;
    h1 {
      color: $dark-color;
    }
  }

  .book-cover {
    display: flex;
    flex-direction: column;

    .book-container {
      display: flex;
      justify-content: center;
      p {
        margin: 2rem 0;

        .cover-img {
          width: 11rem;
          height: 16rem;
        }
      }
    }
  }

  .book-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .book-img {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
    }
    h1 {
      text-align: center;
    }
    img {
      width: 14em;
      height: 21rem;
    }
    .book-content table {
      width: 100%;
    }
  }

  .book-footer {
    display: flex;
    justify-content: center;
    .footer-links {
      display: flex;
      flex-direction: column;
    }
    a {
      margin: 1rem 0;
      width: 200px;
      text-align: center;
    }
  }

  .guestbook-details {
    display: block;
    text-align: center;
    img {
      width: 10rem;
      height: 13rem;
    }
  }

  .gallery-main {
    padding: 2rem 2rem 4rem 2rem;
    .gallery-links {
      display: flex;
      flex-direction: column;
      a {
        margin: 1rem;
      }
    }
  }

  .consulting,
  .graphology {
    img {
      width: 10rem;
      height: 10rem;
    }
  }
}
